import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import { Container, Row, Col } from "reactstrap"
import {
	Layout,
	DisplayH4,
	HR,
	WorkingExperience,
	OrganizationalFlow,
	EquipmentList,
	SEO,
} from "~/components"
import AboutUsBanner from "~/components/Images/AboutUsBanner"

const slugify = require("slugify")

const About = ({ data }) => {
	const aboutUsData = data.allSanityAboutUs.edges.map(data => data.node)

	const [activeMenu, setActiveMenu] = useState("history")

	return (
		<Layout>
			<SEO
				title="About"
				description="DGO Construction - Cebu based construction company where together with our partners and personnel, is committed in giving the best results for our customers."
			/>
			<AboutSection>
				<BannerArea>
					<Banner>
						<AboutUsBanner />
						<Container>
							<Title>ABOUT US</Title>
						</Container>
					</Banner>
				</BannerArea>

				<AboutComponent>
					<Container>
						<Row>
							<Col md={3} sm={12}>
								<Menu>
									{aboutUsData.map(menu => (
										<Link to={`/about/#${slugify(menu.title.toLowerCase())}`}>
											<li
												key={menu.title}
												onClick={() =>
													setActiveMenu(slugify(menu.title.toLowerCase()))
												}
												className={
													menu.title.toLowerCase() === activeMenu
														? "active"
														: null
												}
												onKeyDown={() =>
													setActiveMenu(slugify(menu.title.toLowerCase()))
												}
											>
												
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="8"
													height="10"
													fill="none"
													viewBox="0 0 8 10"
												>
													<path fill="#09175E" d="M8 5l-8 5V0l8 5z"></path>
												</svg>
												{menu.title.toUpperCase()}
											</li>
										</Link>
									))}
								</Menu>
							</Col>
							<Col md={9} sm={12}>
								{aboutUsData.map(data => (
									<AboutUsSection id={slugify(data.title.toLowerCase())}>
										<DisplayH4>{data.heading}</DisplayH4>
										<HR />
										<ReactMarkdown source={data.description} />
										<WorkingExperience data={data.workingExperience} />
										<OrganizationalFlow data={data.organizationalFlow} />
										<EquipmentList data={data.equipments} />
									</AboutUsSection>
								))}
							</Col>
						</Row>
					</Container>
				</AboutComponent>
			</AboutSection>
		</Layout>
	)
}

export default About

const AboutSection = styled.section`
	text-align: center;
	@media (max-width: 575px) {
		h4 {
			font-size: 30px;
		}
	}
`

const BannerArea = styled.div`
	height: 250px;
	position: relative;
`

const Banner = styled.div`
	position: absolute;
	width: 100%;
	& > div {
		height: 250px;
	}
`

const Title = styled.h1`
	top: 125px;
	position: absolute;
	color: #fff;
`

const AboutComponent = styled.div`
	padding: 80px 0;
	@media (max-width: 575px) {
		padding: 40px 0;
	}
	p {
		color: #2c2c2c;
	}
	strong {
		font-size: 22px;
		line-height: 24px;
		display: block;
		margin: 10px 0;
	}
`

const Menu = styled.ul`
	text-align: left;
	list-style: none;
	padding: 40px 0;
	position: sticky;
	top: -2vh;
	a {
		text-decoration: none;
	}
	svg {
		margin-right: 5px;
		vertical-align: inherit;
		@media (max-width: 991px) {
			position: absolute;
			margin-top: 4px;
			left: 5px;
		}
	}

	li {
		padding: 8px 10px;
		color: #000;
		@media (max-width: 991px) {
			position: relative;
			padding-left: 18px;
			font-size: 14px;
		}
	}
	li:hover {
		background: #09175e;
		color: #fff;
		cursor: pointer;
	}
	li:hover svg path {
		fill: #fff200;
	}
	.active {
		background: #09175e;
		color: #fff;
	}
	.active svg path {
		fill: #fff200;
	}
`

const AboutUsSection = styled.div`
	text-align: left;
	padding: 40px;
	@media (max-width: 575px) {
		padding: 15px;
	}
	h4 {
		color: #09175e;
	}
	hr {
		display: inline-block;
		text-align: left;
	}
`

// const EquipmentSection = styled.div`
// 	table {
// 		border-collapse: collapse;
// 		border-radius: 15px;
// 		border-style: hidden;
// 		box-shadow: 0 0 0 1px #c8dade;
// 	}

// 	th,
// 	td {
// 		padding: 14px 25px;
// 	}
// 	th {
// 	}
// 	td {
// 		border: 1px solid #c8dade;
// 	}
// 	thead th {
// 		background: #09175e;
// 		color: #fff;
// 		border-spacing: 0;
// 	}
// 	th:first-child {
// 		border-top-left-radius: 15px;
// 	}
// 	th:last-child {
// 		background: #ffd600;
// 		color: #000;
// 		border-top-right-radius: 15px;
// 	}
// `

export const aboutUsQuery = graphql`
	query {
		allSanityAboutUs(sort: { fields: _createdAt }) {
			edges {
				node {
					id
					title
					heading
					order
					description
					workingExperience {
						year
						experienceTitle
						projectHandled
						duties
					}
					organizationalFlow {
						asset {
							fluid(maxWidth: 500) {
								...GatsbySanityImageFluid
							}
						}
					}
					equipments {
						quantity
						title
					}
				}
			}
		}
	}
`
